@tailwind base;
@tailwind components;
@tailwind utilities;

.ReactCrop__crop-selection {
    border: none !important;
}

.stripes-paused {
    background: repeating-linear-gradient( -45deg, rgb(252 174 30 / 18%), rgb(252 174 30 / 28%) 30px, rgb(252 174 30 / 28%), rgb(252 174 30 / 49%) 30px 60px );
}

/* drop shadow crosshair*/
.ReactCrop .ReactCrop__child-wrapper {
    cursor: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.2444 11.3203H22.8718M11.5581 0.006558V22.634' stroke='%23C1EDCC' stroke-width='2'/%3E%3C/svg%3E") 12.5 12.5, crosshair;
}

/* nothing selected*/
.ReactCrop--invisible-crop img {
    cursor: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.2444 11.3203H22.8718M11.5581 0.006558V22.634' stroke='%23C1EDCC' stroke-width='2'/%3E%3C/svg%3E") 12.5 12.5, crosshair;
}

/* selection crosshair*/
.ReactCrop .ReactCrop__crop-selection {
    cursor: move;
}